// Here you can add other styles

:root{
  --udkh-blue: #005B8A;
  --udkh-lime: #b2c340;
}
@font-face {
    font-family: 'microsoft_yi_baitiregular';
    src: url('fonts/msyi-webfont.woff2') format('woff2'),
         url('fonts/msyi-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: "Antonio", sans-serif;
    color: var(--udkh-blue);
}

body {
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
}

.table {
    color: #191E28;
  }

thead tr{
  border-top: hidden;
}

.c-app {
    --primary: #321fdb;
    --secondary: #ced2d8;
    --success: #2eb85c;
    --info: #39f;
    --warning: #f9b115;
    --danger: #e55353;
    --light: #ebedef;
    --dark: #636f83;
    color: #191E28;
    --color: #3c4b64;
  }

.c-sidebar {
    color: #fff;
    background: var(--udkh-blue);
    font-weight: bold;
 }

 .c-sidebar .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown-toggle {
    color: rgba(255, 255, 255, 0.904);
  }

  .logo{
    display: flexbox;
    flex-direction:row; 
    max-width: 250px;
    max-height: 100px;
    aspect-ratio: initial;
    margin: 10px;
  }

  .video-frame{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 20px 0;
  }

  .video-youtube{
    width: 75%;
    max-width: 900px;
    aspect-ratio: 16/9;  
  }

  .contacts-box{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
      
        
  }
  .contacts{
    width: auto;
    
 }
  
  .contacts ul{
    list-style-type: none;
    padding-left: 5px;
  }

  .plan-vscht{

    width: auto;
    height: auto;
    aspect-ratio: 1/1;
    margin: 10px;
        
  }

  .map {
    width: auto;
    height: auto;
    margin: 10px;
  }

  .logo-game{
    max-width: 150px;
    max-height: 200px;

  }

  .event-info-box {
    display: flex;
    height: auto;
    justify-content:space-between;
    
  }

  .event-info-map {
    width: 50%;
    height: auto;
  }

  .poster-frame{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 0;
  }
  
  .event-poster {
    width:100%;
    height: auto;
    justify-content: center;   
  }

  .event-info-box .video-youtube {
    margin: 20px 0px 20px 20px;
    
  }

input[type="radio"] {
  width: 15px;
  height: 15px;
}

  .pickupLocation{
    display: inline;
    font-size: smaller;
    padding-left: 15px;  
  }
 
  